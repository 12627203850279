.aboutcompanyHeader{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(./../../photo/aboutuspage/header.webp);
    background-position: top;
    min-height: 450px;
}

.aboutcompanyHeadertextbox{
    text-align: center;
    word-break: break-word;
    max-width: 650px;
    /* max-width: 48vw; */
    display: flex;
    justify-content: left;
    min-height: 450px;
    align-items: center;
}

.aboutcompanyHeadertext{
    background-color: rgba(200, 255, 159, 0.233);
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #222;
    padding: 25px;
    font-size: 40px;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
}

.aboutcompanyflag{
    border-radius: 15px 15px 0 0;
    display: flex;
    align-items: center;
    width: 20vw;
    max-width: 200px;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.589);
    margin-top: 15px;
}

.aboutcomapnyfolderbottombox{
    width: 100%;
    display: flex;
    justify-content: right;
}

.aboutcomapnyfolderbottom{
    margin-right: 20px;
    min-width: 150px;
    max-width: 400px;
    width: 20vw;
    height: 40px;
    border-radius: 0 0 15px 70px;
}

.aboutcompanyMainblockContainer{
    padding: 40px 0;
    font-family: 'Raleway', sans-serif;
    background: rgb(236,236,236);
    background: linear-gradient(180deg, rgba(236,236,236,1) 86%, rgba(255,255,255,1) 100%);
}

@media(max-width: 1024px){

    .aboutcompanyHeadertextbox{
        max-width: 50vw;
    }
}

@media(max-width: 768px){

    .aboutcompanyHeadertextbox{
        justify-content: center;
        min-width: 300px;
    }

    .aboutcompanyHeadertext{
        font-size: 2em;
        padding: 10px;
    }
}