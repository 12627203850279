.line{
    position: relative;
    overflow: hidden;
    min-height: 280px;
    font-family: 'Raleway', sans-serif;
}


span {
    height: 100%;
    width: 100%;
}

.portfolioCard{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 530px;
    max-height: 500px;
    margin: 0 10px;
    transform: translate(0%, 0%);
    transition: transform 0.6s linear, opacity 0.3s ease-in-out, max-width 0.5s ease-in-out,
     max-height 0.5s ease-in-out, width 0.5s linear, height 0.5s linear;
}
.portfolio_prev{
    max-width: 300px;
    max-height: 300px;
    transform: translate(-200%, 0%);
    opacity: 1;
}

.portfolio_next{
    max-width: 300px;
    max-height: 300px;
    transform: translate(200%, 0%);
    opacity: 1;
}

.portfolio_prev img{
    max-width: 300px;
    max-height: 300px;
    -webkit-box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
}

.portfolio_next img{
    max-width: 300px;
    max-height: 300px;
    -webkit-box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
}

.portcurrnetCard{
    box-shadow: 0px 0px 0px #666;
    opacity: 1;
    z-index: 2;
}

.portcurrnetCard img{
    max-height: 550px;
    max-width: 650px;
    position: absolute;
    -webkit-box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 10px 4px rgba(0,0,0,0.75);
}

.portfolio_nexthidden{
    max-width: 300px;
    max-height: 200px;
    opacity: 0;
    transform: translate(220%, 0%);
}

.portfolio_nexthidden img{
    max-width: 300px;
    max-height: 200px;
}

.portfolio_prevhidden img{
    max-width: 300px;
    max-height: 200px;
}
.portfolio_prevhidden{
    max-width: 300px;
    max-height: 200px;
    transform: translate(-220%, 0%);
    opacity: 0;
}
.portfolioSlider_btn{
    display: flex;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
    font-size: 1.5em;

}

.portfolioSlider_btn button{
    min-width: 220px;
    background: #429062;
    padding: 15px;
    font-weight: bold;
    
}

.carouselBox{
    -webkit-box-shadow: 0px 7px 38px 15px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 7px 38px 15px rgba(0,0,0,0.75);
    box-shadow: 0px 7px 38px 15px rgba(0,0,0,0.75);    
    background-color: rgb(97, 97, 97);
    position: relative;
    overflow: hidden;
    display: flex;
    height: 460px;
    max-width: 1800px;
    margin: 0 auto;
}

.carouselBox img{
    object-fit: cover;
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 30px;
    -webkit-user-drag: none;
    user-select: none;
    filter: brightness(92%);
}


.carouselCardtitle{
    max-width: 70%;
    font-family: 'Raleway', sans-serif;
    position: absolute;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #222;
    bottom: 10%;
    right: 20px;
    font-size: 30px;
    text-align: right;
}

.carouselCardsa{
    position: absolute;
    transition: transform 0.6s ease-in-out, opacity 0.2s ease-out;
    height: 400px;
    width: 400px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.carouselCards{
    position: absolute;
    height: 400px;
    width: 400px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.caroseulbtn{
    border-radius: 50%; 
    padding: 10px 16px;
    background-color: #666666c5;
    display: flex;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
}

.carouselbtnleft{
    margin-left: 3px;
    left: 0;
}

.carouselbtnleft::after{
    transform: translateX(-2px);
    content: '';
    border-right:12px solid  white;
    border-top:12px solid  transparent;
    border-bottom:12px solid  transparent;
}

.caroseulbtnright{
    margin-right: 3px;
    right: 0;
}

.caroseulbtnright::after{
    transform: translateX(2px);
    content: '';
    border-left:12px solid  white;
    border-top:12px solid  transparent;
    border-bottom:12px solid  transparent;
}

@media(max-width: 1300px){
    .Headerslidertitle{
        font-size: 2.5em;
    }
}

@media(max-width: 1100px){

    .portfolio_prev{
        opacity: 0;
    }
    .portfolio_next{
        opacity: 0;
    }
}

@media(max-width: 1000px){
    .Headerslidertitle span{
        max-width: 700px;
    }
}

@media(max-width: 680px) {

    .portfolioSlider_btn button {
        min-width: auto;
    }

    .portfolioSlider_btn{
        font-size: 0.8em;
    }

    .portfoliobtn{
        min-width: 200px !important;
        font-size: 1.6em !important;
    }

    .portcurrnetCard img{
        max-height: 500px;
        max-width: 500px;
    }
    
}

@media(max-width: 540px){

    .portcurrnetCard img{
        max-height: 310px;
        max-width: 310px;
    }

    .portfolioCards{
        height: 400px !important;
    }

    .Headerslidertitle{
        font-size: 30px;
    }
}

@media(max-width: 399px){
    .carouselBox{
        height: 400px;
    }
    .carouselBox img{
        width: 350px;
        height: 350px;
    }
    .carouselCardsa{
        height: 350px;
        width: 350px;
    }

    .carouselCards{
        height: 350px;
        width: 350px;
    }

    .carouselCardtitle{
        bottom: 10%;
        right: 10px;
        font-size: 25px;
        text-align: right;
    }
    
}

@media(max-width: 359px){
    .carouselBox{
        height: 350px;
    }
    .carouselBox img{
        width: 300px;
        height: 300px;
    }
    .carouselCardsa{
        height: 300px;
        width: 300px;
    }

    .carouselCards{
        height: 300px;
        width: 300px;
    }
    
}