.ACfolder{
    margin: 0 auto 30px;
    max-width: 1600px;
    background-color: rgb(238, 227, 212);;
    padding: 40px 0 60px;
    -webkit-box-shadow: 0px -8px 5px 3px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px -8px 5px 3px rgba(0,0,0,0.25);
    box-shadow: 0px -8px 5px 3px rgba(0,0,0,0.25);
}

.ACfolderhatleft{
    justify-content: flex-start;
}

.ACfolderhatleft .ACfolderhat{
    border-radius: 15px 70px 0 0;
}

.ACfolderhatcontainer{
    display: flex;
    margin: 0 auto;
    max-width: 1500px;
}

.ACfolderhat{
    -webkit-box-shadow: 0px -7px 5px 3px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px -7px 5px 3px rgba(0,0,0,0.25);
    box-shadow: 0px -7px 5px 3px rgba(0,0,0,0.25);
    overflow: hidden;
    display: flex;
    min-width: 150px;
    max-width: 400px;
    width: 20vw;
    height: 60px;
    margin: 0 10px;
    background-color: bisque;
    align-items: center;
}

.ACfolderhatflag::before{
    content: '';
    background-color: gray;
    width: 100%;
    height: 20px;
}

.ACslide{
    margin: 0 auto;
    max-width: 1300px;
}

.aboutcompanypaper{
    -webkit-box-shadow: 0px 4px 5px 6px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 5px 6px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 5px 6px rgba(0,0,0,0.25);
    padding: 20px;
    margin: 15px 5px 5px;
    background-color: white;
    color: black;
}

.aboutcompanypaper img{
    border-radius: 30px;
}

.ACFtitle{
    max-width: 900px;
    margin-top: 50px;
    margin-left: 15px;
}

.ACFtitletext{
    font-weight: 600;
    max-width: 900px;
    padding-bottom: 15px;
    font-size: 2.1em;
    text-align: center;
}

.ACFmainblockinfo{
    max-width: 600px;
    padding: 10px;
}

.ACFtextformat{
    font-size: 1.7em;
    line-height: 130%;
    text-align: justify;
}

.ACFmainblock{
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    justify-content: center;
    align-items: flex-end;
}

@media(max-width: 1280px){
    .ACFmainblockinfo{
        max-width: 440px;
    }
}

@media(max-width: 770px){
    .ACFtitletext {
        font-size: 1.4em;
    }
    .ACFtextformat {
        font-size: 1em;
    }
}