.servicesBlock{
    background-color: rgb(238, 227, 212);
    padding-bottom: 50px;
}

.serviceContainer{
    font-family: 'Raleway', sans-serif;
    max-width: 1600px;
    margin: 0 auto;
    padding: 5px 0;
}

.serviceTitle{
    padding-top: 60px;
    padding-bottom: 50px;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
}


.serviceCardimg{
    height: auto;
    width: 100%;
    max-height: 700px;
    max-width: 600px;
    padding: 0 5px;
    border-radius: 30px;
}

.serviceCard{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
    column-gap: 10px;
    align-items: center;
}

.serviceCard + .serviceCard{
    padding-top: 45px;
}

.serviceCardText{
    padding: 10px 0 25px;
}

.serviceCardTexth2{
    text-align: center;
    font-size: 40px;
    margin-bottom: 40px;
}

.serviceCardTexth3{
    text-align: justify;
    font-size: 1.5em;
    padding: 0 5px;
}

.serviceCardTexth3 + .serviceCardTexth3{
    padding-top: 7px;
}

.serviceCardtextandbtn{
    width: 45vw;
    max-width: 650px;
    min-height: 200px;
    text-align: center;
}

.serviceCardTexthbtn:hover{
    background-color: #51b379;
}

.serviceCardTexthbtn{
    transition: all 0.2s linear;
    margin: 15px auto 0;
    width: 30%;
    min-width: 240px;
    padding: 15px;
    background-color: #429062;
    font-size: 1.5em;
}

@media (max-width: 1125px){
    .serviceCardtextandbtn{
        width: 40vw;
    }
}
@media (max-width: 1030px){
    .serviceCardtextandbtn{
        width: 100%;
    }

    .serviceCard{
        justify-content: center;
    }
    .serviceCardText{
        padding: 0 5px;
        margin-top: 0px;
    }

    .serviceCardTexth2{
        word-break: break-word;
        font-size: 36px;
        margin-bottom: 20px;
    }

    .serviceCardimg{
        height: 100%;
        width: 100%;
        max-height: 400px;
        max-width: 500px;
    }

    .serviceCardTexth3{
        padding: 0 5px;
    }
}

@media (max-width: 1030px){
    .serviceCardTexth3{
        font-size: 1.2em;
    }
}

@media (max-width: 319px){
    .serviceCardTexth2{
        font-size: 30px;
    }
}