.contactblock{
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(75,75,75,1) 80%);
}

.contactContainer{
    max-width: 1400px;
    margin: 0 auto;
    font-family: 'Raleway', sans-serif;
}

.footerContainer{
    text-align: center;
    background-color: rgb(75, 75, 75);
    padding: 7px 0;

}

.contactdataUL a{
   color: black;
   transition: all 0.2s;
}

.contactdataUL a:hover{
    color: blue; 
}

.contactdataLi{
    display: flex;
    margin: 20px 0;
}

.contactdataLiimg{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.contactdataLitextTitle{
    font-size: 28px;
}

.contactdataLitextMain{
    margin-left: 7px;
    font-size: 20px;
}

.contactTitle{
    padding-bottom: 50px;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
}
.contactformTitle{
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
}

.mailsend{
    text-align: center;
    font-size: 1.5em;
    color: #429062;
}


.contactMainbox{
    padding: 0 15px 0 15px;
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: center;
}
.contactdataTitle{
    color: black;
    font-size: 2em;
    padding: 20px 10px 15px;
}
.contactForm{
    background: white;
    box-shadow: -10px 0px 19px 5px rgba(0,0,0,0.24);
    -webkit-box-shadow: -10px 0px 19px 5px rgba(0,0,0,0.24);
    -moz-box-shadow: -10px 0px 19px 5px rgba(0,0,0,0.24);
    min-height: 500px;
    border-radius: 15px 0 0 15px;
    max-width: 460px;
    padding: 20px;
}

.contactdataList{
    padding-left:30px;
}

.contactformInputs{
    padding: 15px 15px 15px 30px;
    width: 90%;
    margin: 10px 0 10px 5px;
    border: 1px solid black;
    border-radius: 5px;
}

.contactinputman{
    background-image: url('./../../../photo/user.svg');
    background-position: 7px;
    background-repeat: no-repeat;
    background-size: 18px 18px;
}

.contactinputtel{
    background-image: url('./../../../photo/phone.svg');
    background-position: 7px;
    background-repeat: no-repeat;
    background-size: 18px 18px;
}

.contactinputmail{
    background-image: url('./../../../photo/envelope.svg');
    background-position: 7px;
    background-repeat: no-repeat;
    background-size: 18px 18px;
}

.contactinputcloud{
    background-image: url('./../../../photo/message-solid.svg');
    background-position: 7px 7px;
    background-repeat: no-repeat;
    background-size: 18px 18px;
}

.contactformButton{
    margin: 20px auto 30px auto;
    border-radius: 15px;
    width: 60%;
    display: block;
    padding: 15px;
    background-color: #429062;
    transition: all 0.3s;
}

.contactformButton:hover{
    background-color: green;
}

.contactformTextarea{
    resize: none;
    padding: 5px 0 0 30px;
    width: 90%;
    margin: 10px 0 10px 5px;
    border: 1px solid black;
    border-radius: 5px;
    height: 180px;
}
.contactdatablock{
    box-shadow: 4px 0px 13px 13px rgba(0,0,0,0.31);
    -webkit-box-shadow: 4px 0px 13px 13px rgba(0,0,0,0.31);
    -moz-box-shadow: 4px 0px 13px 13px rgba(0,0,0,0.31);
    margin: -20px 0;
    border-radius: 15px;
    background-color: #50b378;
    max-width: 400px;
    width: 100%;
}

@media(max-width: 889px){
    .contactForm {
        border-radius: 0px;
        margin-bottom: 0px;
    }
    .contactdatablock{
        margin: 0;
        border-radius: 0;
    }
    .contactMainbox {
        row-gap: 0px;
    }
}