@import './reset.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');

/* 
font-family: 'DM Sans', sans-serif;
font-family: 'Poppins', sans-serif; */
a:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
}
a{
    scroll-behavior: smooth;
    color: black;
}
#root{
    min-height: 100vh;
}

:root{
    --black: #171718;
    --black-border: #26292D;
    --white: #fff;
    --purple: #5c62ec;

    --black-text-color: var(--black);
    --dark-bg: var(--black);
    --dark-border: var(--black-border);
    --header-text: var(--white);
    --accent: var(--purple);

    --page-bg: var(--white);
    --text-color: var(--black);

    --title-1: var(--accent)

}