.ACfolderhatcentral{
    justify-content: center;
}

.ACfolderhatcentral .ACfolderhat{
    border-radius: 70px 70px 0 0;
}

.aboutcompanyendtext{
    font-family: 'Raleway', sans-serif;
    display: flex;
    justify-content: center;
    margin: 30px 0;
}

.aboutcompanyendtextbox{
    max-width: 900px;
    font-size: 2.2em;
    font-weight: bold;
    text-align: center;
}

.ACTlist{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
}

@media(max-width: 650px){
    .aboutcompanyendtextbox{
        font-size: 1.6em;
    }
}