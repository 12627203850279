@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&family=Raleway&display=swap');

.advantageContainer{
    font-family: 'Raleway', sans-serif;
    max-width: 1600px;
    margin: 0 auto;
}
.advantageTitle{
    display: flex;
    justify-content: center;
    padding: 40px 0;
    text-align: center;
}

.advantageHeader{
    font-size: 42px;
    font-weight: 700;
}

.advantageBlock{
    padding: 10px 10px 0 10px;
}

.advantageCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;
}

.advantageCard{
    width: 45%;
    text-align: center;
    word-wrap: break-word;
}

.advantageCard_icon{
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.advantageCard_icon img{
    width: 80px;
    height: 80px;
}

.advantageCard_header2{
    font-size: 40px;
    padding: 5px 0;
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.advantageCard_header3{
    font-size: 1.5em;
    padding: 5px 5px 10px 5px;
    text-align: justify;
}

@media(max-width: 860px){
    .advantageCard_header2{
        min-height: 140px;
    }
}

@media(max-width: 768px){
    .advantageCard_header2{
        font-size: 2em;
        min-height:90px;
    }
    .advantageCard{
        width: 80%;
    }
    .advantageCard_icon{
        width: 100px;
        height: 100px;
    }
}

@media(max-width: 400px){
    .advantageCard_header3{
        font-size: 1.2em;
    }
}