.serviceunittop{
    height: 50vh;
    max-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

.sUnittopTitle{
    height: 350px;
    padding: 150px 0;
    font-size: 40px;
    font-weight: 700;
    color: rgb(0, 255, 0);
    display: flex;
    justify-content: center;
    text-align: center;
}

.sUnitmaininfo{
    min-height: 250px;
    max-width: 1400px;
    margin: 0 auto 50px;
}

.sUnittext{
    max-width: 600px;
}

.sUnitTitle{
    max-width: 1400px;
    margin: 16px auto 24px;
    font-size: 24px;
    font-weight: 400;
    color: black;
}

.serviceunit{
    font-family: 'Raleway', sans-serif;
}
.sUnitimg{
    margin: 0 auto;

}
.sUnittextandfoto{
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 30px;
}

.sUnitservicetext{
    line-height: 145%;
    text-align: justify;
    font-size: 20px;
    margin: 0px 5px 0 7px;
}

.sUnitservicetext + .sUnitservicetext{
    margin-top: 15px;
}

.sUnitimg img{
    padding-right: 4px;
    display: block;
}

@media(max-width: 1024px){
    .serviceunittop{
        height: 50vh;
        min-height: 350px;
    }
}

@media(max-width: 768px){
    .serviceunittopTitle{
        min-height: 30vh;
    }
}