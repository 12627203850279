.portfolioContainer{
    max-width: 1400px;
    margin: 0 auto;
    padding-bottom: 30px;
    font-family: 'Raleway', sans-serif;
}

.portfolioblock{
    background-color: rgb(238, 227, 212);
}

.portfolioCards{
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    transition: all 0.5s linear;

}

.portfolioCard img{
    -webkit-user-drag: none;
    border-radius: 30px;
    transition: transform 0.6s linear, opacity 0.3s ease-in-out, max-width 0.5s ease-in-out,
    max-height 0.5s ease-in-out, width 0.5s linear, height 0.5s linear;
}

.portfolioTitle{
    padding-top: 40px;
    padding-bottom: 30px;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
}

.portfoliobutton{
    text-align: center;
    margin-top: 80px;
}

.portfoliobtn{
    font-size: 2em;
    width: 40%;
    min-width: 280px;
    padding: 15px;
    background-color: #429062;
    transition: all 0.2s linear;
}

.portfoliobtn:hover{
    background-color: #51b379;
}