.sUnitimgSizes{
    height: 500px;
    width: 500px;
}

.disabledimg{
    width: 0px !important;
    padding-right: 0px !important;
}


.sUnitimagecontainer{
    position: relative;
    display: flex;
    align-items: center;
    width: 500px;
    height: 500px;
    overflow: hidden;
}

.sUnitimagebtns{
    justify-content: center;
    display: flex;
    row-gap: 20px;
    column-gap: 20px;
    text-align: center;
    margin-top: 20px;
}

.sUnitimagebtns button{
    background: rgba(68, 129, 39, 0.699);
    padding: 15px;
    font-weight: bold;
}


@media(max-width: 550px){
    .sUnitimagecontainer{
        width: 300px;
        height: 300px;
    }

    .sUnitimgSizes{
        height: 300px;
        width: 300px;
    }
}