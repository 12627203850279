.headerblock{
    min-height: 830px;
    height: 85vh;
    background-image: url('./../../../photo/header.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    font-family: 'Raleway', sans-serif;
    
}

.headercontainer{
    min-height: 700px;
    width: 1200px;
    margin: 0 auto;
}

.headerslider{
    margin-bottom: 30px;
    width: 100%;
    -webkit-box-shadow: 0px -25px 21px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -25px 21px 0px rgba(0,0,0,0.75);
    box-shadow: 0px -25px 21px 0px rgba(0,0,0,0.75);
    background-color: rgb(97, 97, 97, 0.4);
}

.headerbox{
    color: black;
    max-width: 440px;
    min-width: 100px;
    background-color: rgba(243, 255, 234, 0.8);
    padding: 5px;
    margin-bottom: 50px;
    margin-top: 5px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
}

.headerbox_h1{
    text-transform: uppercase;
    margin-top: 15%;
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 30px;
}

.headerbox_h2{
    font-size: 30px;
    font-weight: 400;
}

.headerbox_h2 + .headerbox_h2{
    margin-top: 20px;
}

.headerbox_btn{
    font-size: 24px;
    padding: 20px 30px;
    background-color: #429062;
    margin-top: 20px;
    margin-bottom: 30px;
    max-height: 100px;
    transition: all 0.2s linear;
}

.headerbox_btn:hover{
    background-color: #51b379;
}

@media(max-width: 1600px){
    .headerblock{
        min-height: 830px;
        height: 830px;
    }
}

@media(max-width: 500px){
    .headercontainer{
        min-height: 100%;
    }

    .headerbox_h1{
        font-size: 1.8em;
    }
}