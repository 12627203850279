.nav{
    font-family: 'Raleway', sans-serif;
    background-color: rgb(243, 255, 234);
}
.sidenav{
    font-family: 'Raleway', sans-serif;
}

.nav_row{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
}

.textContainer{
    z-index: 3;
    margin-left: auto;
    padding: 0 15px;
    font-size: 18px;
    display: flex;
    cursor: pointer;
}

.textContainer .textContainer__item:not(:first-child)::before{
    content: '|';
    padding-left: 8px;
    padding-right: 8px;
}

.textContainer__item{
    text-align: center;
    margin: auto 0;
    transition: all 0.3s;
    text-transform: uppercase;
}
.logo{
    width: 100%;
    max-width: 250px;
    height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 250px 50px;
    background-image: url('./../../../photo/logopro2.png');
}

.logocontainer{
    width: 100%;
    max-width: 250px;
    height: 70px;
}
.mobile_menu{
    margin-left: auto;
    display: none;
    height: 70px;
    width: 70px;
}
.mobile_menu img{
    width: 35px;
    height: 35px;
    margin: auto;
}

.nav-menu{
    position: fixed;
    height: 100vh;
    width: 225px;
    background-color: rgb(243, 255, 234);
    top: 0;
    right: -100%;
    transition: 850ms;
    z-index: 4;
}

.languageSwitch{
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
    padding: 0 10px;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
}
.languageactive{
    border-bottom: 1px solid black !important;
}

.languageSwitch li{
    margin: auto 0;
    border: 1px solid transparent;
}

.languageSwitch a{
    margin: auto 0;
    border: 1px solid transparent;
}

.nav-menu.active{
    right: 0;
    transition: 350ms;
    z-index: 3;
}

.nav-menu__active{
    display: flex;
}

.nav-menu__exiter{
    display: none;
    background-color: black;
    position: fixed;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    top: 0;
    transition: 350ms;
}

.nav-menu__exiter.active{
    display: flex;
    opacity: 0.8;
    z-index: 3;
}

.nav-bar__list{
    padding-top: 25px;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    column-gap: 20px;
    text-align: left;
}



.nav_languageSwitch{
    display: flex;
    row-gap: 20px;
    column-gap: 20px;
    padding: 10px 10px 0 10px;
    font-size: 24px;
    cursor: pointer;
    justify-content: flex-end;
}

.dropdown_menu{
    display: none;
    position: absolute;
    padding-top: 22px;
}

.dropdown_menu .textContainer__item{
    text-transform: capitalize;
}

.dropdown_menu.active{
    display: block;
}

.dropdown_menu--mobile{
    max-height: 0%;
    background-color: rgb(228, 250, 210);
    transition: opacity 0.7s ease-in-out, max-height 0.7s ease-in-out, padding 0.7s ease-in-out, margin-top 0.7s ease-in-out;
    position: relative;
    display: block;
}

.dropdown_menu--mobile .textContainer__item{
    max-height: 0;
    opacity: 0;
    text-align: left;
    transition: opacity 0.7s ease-in-out, max-height 0.7s ease-in-out, padding 0.7s ease-in-out, margin-top 0.7s ease-in-out;
}

.drop_active{
    margin-top: 9px;
    max-height: 100%;
    padding: 10px 2px 5px 2px;
}
.dropdown_menu--mobile.drop_active{
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.dropdown_menu--mobile  a{
    pointer-events: none;
}

.dropdown_menu--mobile.drop_active  a{
    pointer-events: all;
}

.dropdown_menu--mobile.drop_active .textContainer__item{
    max-height: 100px;
    opacity: 1;
    padding: 5px 0 5px 4px;
    text-align: left;
}

.dropdown_menu li{
    min-width: 100px;
    max-width: 200px;
    padding: 10px 4px;
    font-size: 18px;
    background-color: rgb(243, 255, 234);
    text-align: left;
}
.dropdown_menu--mobile li:hover{
    background-color: rgb(93, 214, 0);
}
.dropdown_menu li:hover{
    background-color: rgb(93, 214, 0);
}

.ulList{
    margin-left: 3px;
}


@media(max-width: 1020px){
    .mobile_menu{
        display: flex;
    }
    .textContainer{
        display: none;
    }
    .languageSwitch{
        display: none;
    }
}

@media(max-width: 500px){
}

@media(max-width: 290px){
    .logocontainer{
        max-width: 220px;
    }

    .logo{
        max-width: 220px;
        height: 70px;
        background-size: 220px 50px;
    }
}
