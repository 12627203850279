.aboutusBlock{
    max-width: 1600px;
    margin: 0 auto;
    font-family: 'Raleway', sans-serif;
}

.aboutusTitleheader{
    padding-bottom: 50px;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
}

.aboutusMainbox{
    display: flex;
    justify-content: space-around;
    row-gap: 25px;
    flex-wrap: wrap;
}

.aboutusPhoto{
    background-image: url('./../../../photo/aboutus.webp');
    background-size: cover;
    background-repeat: no-repeat;
    height: 700px;
    width: 500px;
    border-radius: 30px;
}

.aboutsusTextbox{
    max-width: 900px;
    padding: 0 10px;
}

.aboutsusTextbox__title{

    padding-bottom: 10px;
    font-size: 30px;
    font-weight: 800;
}

.aboutsusTextbox__abzac{
    font-size: 1.3em;
}

.aboutsusTextbox__abzac p{
    padding: 8px 0;
    text-align: justify
}

@media(max-width: 929px){
    .aboutsusTextbox__title{
        text-align: center;
    }
}

@media(max-width: 540px){
    .aboutusPhoto{
        height: 100vw;
    }

    .aboutsusTextbox__abzac{
        font-size: 1em;
    }
}