.portfoliopageContainer{
    background: white;
    font-family: 'Raleway', sans-serif;
}

.portfoliopageTitle{
    display: flex;
    justify-content: center;
    font-size: 40px;
    padding: 100px 0 0 0;
}

.portfoliopageGallry{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    padding: 50px 0;
}

.portfoliopageGallry span{
    width: auto;
    height: auto;
    min-width: 280px;
}

.portfoliopageBlock{
    max-width: 1800px;
    margin: 0 auto;
}

.portfoliopageGallry img{
    border-radius: 30px;
    cursor: pointer;
    max-width: 600px;
    height: 350px;
}

.portfoliopageImagecontainer{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.836);
    position: fixed;
    top: 0;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    
}

.portfoliopageImagecontainer::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.imagezoomTopbox{ 
    height: 5vw;
    min-height: 75px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.imagezoomTopboxpagination{
    color: white;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    height: 100%;
    width: 7vw;
    min-width: 100px;
    display: flex;
    background: rgba(35,35,35,.65);
    justify-content: center;
    align-items: center;
    font-size: 1.5vw;
}

.imagezoomTopboxbtn{
    cursor: pointer;
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    height: 100%;
    width: 5vw;
    min-width: 75px;
    display: flex;
    justify-content: center;
    background: rgba(35,35,35,.65);
}

.imagezoomTopboxbtn img{
    width: 5vw;
    min-width: 75px;
}

.portleftArrow{
    left: 0;

}
.imagezoomArrow img{
    width: 3vw;
    height: 3vw;
    max-width: 80px;
    max-height: 80px;
    margin: 5px;
}
.imagezoomArrow{
    background: rgba(35,35,35,.65);
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 6px;
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 3;
    transform: translateY(-50%);
}
.portrightArrow{
    right: 0;
}

.imagezoomImagebox{
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
}

.imagezoomImage{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagezoomImage img{
    max-height: 90%;
}


@media(max-width: 1200px){
    .imagezoomTopbox{ 
        height: 5vw;
        min-height: 50px;
    }
    
    .imagezoomTopboxpagination{
        width: 5vw;
        min-width: 75px;
    }
    
    .imagezoomTopboxbtn{
        width: 5vw;
        min-width: 50px;
    }
    
    .imagezoomTopboxbtn img{
        width: 5vw;
        min-width: 50px;
    }
}

@media(max-width: 800px){
.imagezoomTopboxpagination {
    font-size: 1em;
    }
}

@media(max-width: 700px){
    .imagezoomArrow{
        padding: 6px 6px 6px 3px;
    }

    .imagezoomArrow img{
        width: 20px;
        height: 20px;
        margin: 3px;
    }
}
@media(max-width: 600px){
    .portfoliopageGallry img{
        max-width: 300px;
        max-height: 350px;
        height: auto;
    }
}

@media(max-width: 300px){
    .portfoliopageGallry img{
        max-width: 250px;
        max-height: 250px;
    }
}