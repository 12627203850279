.sUnittablepackegname{
    width: 100%;
    padding: 15px 30px;
    font-size: 2.2em;
    text-align: center;
    color: white;
    background-color: black;
}

.sUnitpricesblockTitle{
    display: flex;
    justify-content: center;
    font-size: 2.7em;
    margin-bottom: 30px;
    font-weight: bold;
    text-align: center;
}

.sUnittablecontain{
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(218, 218, 218);
    font-size: 1.7em;
}
.sUnitprices{
    padding-top: 40px;
}
.sUnittables{
    align-items: baseline;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 40px;
}

.sUnittable{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    font-family: 'Raleway', sans-serif;
    max-width: 1050px;
    width: 95vw;
    display: flex;
    flex-wrap: wrap;
}

.sUnittabletitle{
    font-size: 1.7em !important;
    padding: 15px 0 25px 35px !important;
}

.sUnittableneed{
    width: 50%;
}

.sUnittableneed:nth-of-type(1){
    border-right: 1px solid rgb(175, 175, 175); 
    padding-bottom: 20px;
}

.sUnittableneed div{
    padding: 15px 10px;
    text-align: center;
}

.sUnittableneed li:not(:first-child){
    font-size: 1.4em;
    margin: 0 30px;
    border-bottom: 2px solid rgb(175, 175, 175);
}

.sUnittableneed li:last-child{
    border: none;
}

.sUnittablehavesubject{
    padding-top: 25px;
}

.sUnittableneedsubject{
    padding-top: 15px;
}

.sUnitbonusinfo{
margin: 0 15px;
}

.sUnittablebonusinfo{
    width: 100%;
    font-size: 1.4em;
    padding: 30px 15px 20px;
}

.sUnitafterwords{
    padding-bottom: 30px;
}
.sUnitafterwords li + li{
margin-top: 7px;
}

.sUnitafterwords li:first-child{
    margin-top: 15px;
}

.sUnittablebonusinfo li + li{
    margin-top: 10px;
}

@media(max-width: 768px){

    .sUnittableneed:nth-of-type(1){
        border-right: none; 
        border-bottom: 1px solid rgb(175, 175, 175);
    }

    .sUnittableneed {
        width: 100%;
    }
}