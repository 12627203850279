.ACfolderhatright{
    justify-content: flex-end;;
}

.ACfolderhatright .ACfolderhat{
    border-radius: 70px 15px 0 0;
}

.ACTlistunit{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    justify-content: center;
}

.ACTlistunit img{
    width: 100%;
    max-width: 600px;
}

.ACTlistunit h2{
    text-align: justify;
    display: flex;
    align-items: center;
    padding: 10px;
    max-width: 600px;
    font-size: 1.7em;
}

.ACSheaders{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 30px;
    padding-top: 20px;
}

.ACSheaders div{
    font-size: 28px;
    max-width: 600px;
}

.ACSheaders h2{
    padding: 10px;
    text-align: justify;
}

.ACblueflag{
    background-color: blue;
    width: 100%;
    height: 20px;
}

.ACSblockendtext{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.ACSblockendtext h2{
    padding: 10px;
    max-width: 800px;
    font-size: 32px;
}

@media (max-width: 1049px){
    .ACSheaders{
        justify-content: center;
    }
}


@media (max-width: 770px){
    .ACSheaders div {
        font-size: 1em;
    }

    .ACSblockendtext h2{
        font-size: 1.3em;
    }
}

@media (max-width: 599px){
    .ACTlistunit h2{
        font-size: 1em;
    }
}